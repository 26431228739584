import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";

/**
 * Calls fetch with the given URL and options.
 *
 * This should only be used where Turbo cannot be used, i.e. in the user-facing
 * parts of the app, since Turbo is disabled.
 */
export default class extends Controller {
  static values = {
    method: { type: String, default: "post" },
    url: String,
  };

  async fetch(event) {
    event.preventDefault();

    this.dispatch("started");

    try {
      const request = new FetchRequest(this.methodValue, this.urlValue);
      const response = await request.perform();
      if (response.ok) {
        this.dispatch("succeeded");
      } else {
        this.dispatch("failed");
      }
    } catch {
      // Depending on how the error is handled by the server, we may get the
      // here, or in the else above.
      this.dispatch("failed");
    } finally {
      this.dispatch("stop"); // just in case
    }
  }
}
