import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["failureIndicator", "successIndicator", "workingIndicator"];

  start() {
    this.failureIndicatorTarget.classList.add("d-none");
    this.successIndicatorTarget.classList.add("d-none");
    this.workingIndicatorTarget.classList.remove("d-none");
  }

  stop() {
    this.workingIndicatorTarget.classList.add("d-none");
  }

  succeeded() {
    this.stop();
    this.successIndicatorTarget.classList.remove("d-none");
  }

  failed() {
    this.stop();
    this.failureIndicatorTarget.classList.remove("d-none");
  }
}
