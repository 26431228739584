import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  connect() {
    const modalId =
      this.element.dataset.bsTarget || this.element.getAttribute("href");
    if (modalId) {
      const modalElement = document.querySelector(modalId);
      if (modalElement) {
        const options = this.getModalOptions(modalElement);
        this.modal = new Modal(modalElement, options);

        this.element.addEventListener("click", (event) => {
          event.preventDefault();
          this.modal.show();
        });
      }
    }
  }

  getModalOptions(modalElement) {
    return {
      backdrop:
        modalElement.dataset.backdrop !== undefined
          ? modalElement.dataset.backdrop
          : true,
      keyboard:
        modalElement.dataset.keyboard !== undefined
          ? modalElement.dataset.keyboard !== "false"
          : true,
      focus:
        modalElement.dataset.focus !== undefined
          ? modalElement.dataset.focus !== "false"
          : true,
      show:
        modalElement.dataset.show !== undefined
          ? modalElement.dataset.show === "true"
          : false,
    };
  }
}
